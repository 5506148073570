import React from 'react';
import Spinnerloader from '../Spinnerloader/Spinnerloader';

const LazyLoader = () => {
  return (
    <div className="h-[100vh] flex items-center justify-center">
      <Spinnerloader />
    </div>
  );
};

export default LazyLoader;
