import React, { useState } from "react";

const Faq = () => {
  const questionsAndAnswers = [
    {
      question: "Who is Fadia and where is she from?",
      answer:
        "Fadia is a culinary enthusiast from Lebanon. She has a passion for cooking and sharing the rich flavors of Lebanese cuisine, influenced by the Mediterranean coast and a mix of different civilizations.",
    },
    {
      question: "What does Fadia believe about food?",
      answer:
        "Fadia believes that food has a unique power to unite people, enable conversations, and connect us to our roots. She aims to help people share the warmth of cooking and eating together.",
    },
    {
      question: "What services does Fadia offer?",
      answer:
        "Fadia offers authentic cooking experiences, catering for events, takeaway days, and unique dips. She also provides private chef services and flexible party packages that include cooking and belly dancing.",
    },
    {
      question: "Where can Fadia's cooking experiences take place?",
      answer:
        "Fadia's cooking experiences can be held at her home, the student's home, a cooking studio, or a room rented for the occasion.",
    },
    {
      question: "What are some dishes Fadia teaches in her cooking classes?",
      answer:
        "In her cooking classes, Fadia teaches a variety of Lebanese dishes, including Shourbet Adas (lentil soup), Fattet Makdous, Arnabit b Tahini (cauliflower with tahini sauce), Tabbouleh, and Moutabal.",
    },
    {
      question: "What are the prices for Fadia's cooking experiences and catering services?",
      answer:
        "Cooking experiences are priced at £35 per person for 2.5 hours (minimum group of 8), and private cooking classes at £58 per person (minimum group of 4). Catering services are also available at £35 per person.",
    },
    {
      question: "How can one contact Fadia or learn more about her services?",
      answer:
        "Fadia can be contacted through her social media handles, @fadiasfoodadventure, and her Facebook page. She is also available via email for inquiries about her cookbook, cooking experiences, and catering services.",
    },
    {
      question: "What are Fadia's contributions to the community?",
      answer:
        "Fadia has collaborated with the St. Albans Community Pantry, created meals with rescue food, and supported 'Helping the Homeless.' She also aims to establish a second food bank site in Oysterfields.",
    },
    {
      question: "What is Fadia known for on social media?",
      answer:
        "Fadia is known for her unique dips, healthy and diet-friendly recipes, and as a culinary influencer under the handle @fadiasfoodadventure. She shares her food journey and offers cooking tips on social media.",
    },
    {
      question: "What is the main focus of Fadia's mission in her culinary journey?",
      answer:
        "Fadia's mission is to make everyday cooking a pleasure, promote the joy of cooking, and foster a sense of community through shared culinary experiences. She believes in the power of cooking to bring people together and enrich their lives.",
    }
  ];
  
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <>
      <div class="relative mx-auto w-full py-16 px-5 font-sans text-gray-800 sm:px-20 md:max-w-screen-lg lg:py-24">
        <h2 class="mb-5 text-center font-sans text-4xl sm:text-5xl font-bold underline underline-offset-4 ">
          Frequently asked Questions
        </h2>
        <p class="mb-12 text-center text-lg text-gray-600">
          We have written down answers to some of the frequently asked
          questions. But, if you still have any queries, feel free to ping us on
          chat.
        </p>
        <ul class="space-y-4">
          {questionsAndAnswers?.map((item,index) => (
            <li key={index} class="text-left">
              <label
                for={`accordion-${index}`}
                class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
              >
                <input
                  class="peer hidden"
                  type="checkbox"
                  id={`accordion-${index}`}
                  checked={openIndex === index}
                  onChange={() => handleToggle(index)}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute right-0 top-4 ml-auto mr-5 h-4 text-teal-500 transition peer-checked:rotate-180"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
                <div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
                  <h3 class="text-sm text-gray-600 lg:text-base">
                    {item?.question}
                  </h3>
                </div>
                <div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
                  <div class="p-5">
                    <p class="text-sm text-teal-600">{item?.answer}</p>
                  </div>
                </div>
              </label>
            </li>
          ))}
        </ul>
    
      </div>
    </>
  );
};

export default Faq;
