import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/cooking-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../Redux/slice/authSlice';
import Fade from 'react-reveal/Fade';

import Toast from '../Toast/Toast';
import userImage from '../../assets/woman.png';
const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Events', href: '/search/events' },
  { name: 'Courses', href: '/search/courses' },
  { name: 'Recipes', href: '/search/recipes' },
  { name: 'About', href: '/about' },
  { name: 'Contact', href: '/contactus' },
  { name: 'Login', href: '/admin/login' },
  // { name: "Register", href: "/registration" },
];

const loginUserNavigation = [
  { name: 'Home', href: '/' },
  { name: 'Add Blog', href: '/create-blog' },
  { name: 'My Blogs', href: '/my-blogs' },
  // { name: "Contact", href: "/contactus" },
  // { name: "About", href: "/about" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const { token, user } = useSelector((state) => state?.authSlice);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleComingSoon = () => {
    Toast.warning('Coming Soon..');
  };

  return (
    <Disclosure as="nav" className="bg-teal-700">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 py-4 sm:px-6 lg:px-8">
            <div className="relative flex h-20 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-between">
                <Fade bottom>
                  <div className="flex flex-shrink-0 items-center">
                    <Link to="/">
                      <img
                        className="rounded-full h-20 w-auto"
                        src={Logo}
                        alt="Your Company"
                      />
                    </Link>
                  </div>
                </Fade>
                <div className="hidden sm:ml-6 sm:flex items-center float-left">
                  <div className="flex space-x-4">
                    {!token &&
                      navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            location.pathname === item.href
                              ? 'bg-white text-teal-700'
                              : 'text-white hover:bg-gray-100 hover:text-teal-700',
                            'rounded-md px-3 py-2 transition-all duration-500 ease-in-out text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    {token &&
                      loginUserNavigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            location.pathname === item.href
                              ? 'bg-white text-teal-700'
                              : 'text-white hover:bg-gray-100 hover:text-teal-700',
                            'rounded-md px-3 py-2 text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                {token && (
                  <>
                    <p className="text-white underline">{user?.name}</p>
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={userImage}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/settings"
                                // onClick={handleComingSoon}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Settings
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <p
                                onClick={() => {
                                  dispatch(logoutUser());
                                  navigate('/');
                                }}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Sign out
                              </p>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {!token &&
                navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-white  hover:text-teal-800',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              {token &&
                loginUserNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-white  hover:text-teal-800',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
