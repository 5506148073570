import React from 'react';
import { Link } from 'react-router-dom';
import foodImage from '../../assets/bgbanner.webp'; // Image ka path yahan import karen
import Fade from 'react-reveal/Fade';

const HeroSection = () => {
  return (
    <div
      className="relative mx-auto flex w-screen flex-col  bg-green-50 px-4"
      style={{
        backgroundImage: `url(${foodImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <Fade bottom>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white">
          <p className="sm:text-lg font-semibold text-teal-500  p-2 rounded-md mt-20 sm:mt-0">
            Born from instinct, not instructions.
          </p>
          <h1 className="mx-auto mt-2 max-w-5xl text-2xl font-semibold leading-tight sm:text-4xl md:text-5xl  p-2 rounded-md">
            Welcome to Fadia's Food Adventure - Authentic Lebanese Cuisine
          </h1>
          <p className="mx-auto hidden sm:block mt-4 max-w-5xl text-white sm:mt-8 sm:text-lg bg-teal-600 bg-opacity-70 p-2 rounded-md">
            My name is Fadia and I'm from Lebanon. My hobbies include
            socialising, sewing, gardening & cooking. I love cooking and the
            smell of freshly prepared food. Lebanese cuisine is a rich,
            delightful fusion arising of its location and history: At the heart
            of the Middle East on the Mediterranean coast, and over the
            centuries, home to several different civilisations, the Ottoman
            empire and at one time also French administration. This has resulted
            in a unique cooking style internationally recognised.
          </p>

          <div className="mx-auto mt-8 mb-20 flex w-full flex-col space-y-2 sm:w-auto sm:flex-row sm:space-y-0 sm:space-x-6">
            <button className="rounded-full bg-teal-600 px-10 py-3 font-medium text-white hover:opacity-80 sm:w-auto">
              <Link to="/">Explore Articles</Link>
            </button>
            <button className="rounded-full border-2 border-teal-600 px-10 py-3 font-medium text-black transition hover:bg-teal-600 hover:text-white sm:w-auto">
              Subscribe to Newsletter
            </button>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default HeroSection;
