import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { editBlogApi, getBlogByIdApi } from '../../Api/userapi';
import SpinnerButton from '../../components/Spinner/Spinner';
import Toast from '../../components/Toast/Toast';

function BlogPageEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = useSelector((state) => state?.authSlice?.token?.token);
  const [isloading, setIsloading] = useState(false);
  const [fetchDataLoading, setfetchDataLoading] = useState(false);
  const [showimage, setshowimage] = useState(null);

  // Separate state variables
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState(null);

  const [errors, setErrors] = useState({
    title: '',
    content: '',
    category: '',
    image: '',
  });

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link', 'image'],
        ['clean'],
      ],
    },
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  // Handlers for each input field
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleCategorySelect = (category) => {
    setCategory(category);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setshowimage(imageUrl);
    setImage(file);
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {
      title: '',
      content: '',
      category: '',
    };

    if (!title.trim()) {
      newErrors.title = 'Title is required';
      isValid = false;
    }
    if (!content.trim()) {
      newErrors.content = 'Content is required';
      isValid = false;
    }
    if (!category.trim()) {
      newErrors.category = 'Category is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleEditBlog = async () => {
    if (!validate()) return;
    try {
      setIsloading(true);
      const updatedBlog = {
        title,
        content,
        category,
        image,
      };
      await editBlogApi(updatedBlog, token, id);
      Toast.success('Blog Updated Successfully');
      setIsloading(false);
      navigate('/my-blogs');
    } catch (error) {
      setIsloading(false);
      console.log(error);
    }
  };

  const blog_categories = ['Events', 'Courses', 'Recipes'];

  const fetchBlogData = async (id, token) => {
    setfetchDataLoading(true);
    try {
      const response = await getBlogByIdApi(token, id);
      const blogData = response?.data?.blog;

      // Set individual state variables from the response
      setTitle(blogData?.title || '');
      setContent(blogData?.content || '');
      setCategory(blogData?.category || '');
      setImage(blogData?.image || null);

      setshowimage(blogData?.image || null);

      setfetchDataLoading(false);
    } catch (error) {
      console.error('Error in fetching blog data', error);
      setfetchDataLoading(false);
    }
  };

  useEffect(() => {
    if (id && token) {
      fetchBlogData(id, token);
    }
  }, [id, token]);

  return (
    <div>
      <div class="lg:m-10">
        <form class="relative border border-gray-100 space-y-3 mx-auto rounded-md bg-white p-6 shadow-xl lg:p-10">
          <h1 class="mb-6 text-xl font-semibold lg:text-2xl">
            Update Blog Post
          </h1>
          <div>
            <label class="">Blog Title</label>
            <input
              name="title"
              value={title}
              onChange={handleTitleChange}
              type="text"
              placeholder="Blog Title Here..."
              class="mt-2 h-12 w-full rounded-md bg-white px-3 border-2 border-black-500"
            />
            {errors.title && <p class="text-red-500">{errors.title}</p>}
          </div>
          <div class="">
            <label class="">Blog Content</label>
            <ReactQuill
              modules={modules}
              formats={formats}
              theme="snow"
              value={content}
              onChange={handleContentChange}
              style={{
                height: '100vh',
                marginBottom: '4rem',
                width: '100%',
                borderradius: '0.25rem',
              }}
            />
            {errors.content && <p class="text-red-500">{errors.content}</p>}
          </div>
          <div class="grid gap-3 lg:grid-cols-2">
            <div>
              <label class="">Blog Category</label>
              <div class="relative w-56 mt-2 bg-white rounded-lg">
                <input
                  class="peer hidden"
                  type="checkbox"
                  name="select-1"
                  id="select-1"
                />
                <label
                  for="select-1"
                  class="flex w-full cursor-pointer rounded-lg select-none border p-2 px-3 text-sm text-gray-700 ring-blue-400 peer-checked:ring"
                >
                  {category ? category : 'Select Category'}
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="pointer-events-none absolute right-5 top-3 h-4 text-gray-600 transition peer-checked:rotate-180"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
                <ul class="max-h-0 select-none flex-col overflow-hidden rounded-b-lg shadow-md transition-all duration-300 peer-checked:max-h-[24vh] overflow-y-scroll peer-checked:py-3">
                  {blog_categories?.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => handleCategorySelect(item)}
                      class="cursor-pointer px-3 py-2 text-sm text-gray-500 hover:bg-blue-500 hover:text-white"
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              {errors.category && <p class="text-red-500">{errors.category}</p>}
            </div>
            <div>
              <label class="">Choose Image</label>
              <div class="relative w-56 mt-2 bg-white rounded-lg">
                <input onChange={handleFileChange} type="file" />
              </div>
              {errors.image && <p class="text-red-500">{errors.image}</p>}
            </div>
            {showimage && (
              <div className="h-[300px] w-[300px] border-2">
                <img
                  src={showimage}
                  class="h-[100%] w-[100%]"
                  alt="Blog Image"
                />
              </div>
            )}
          </div>
          <div>
            <SpinnerButton
              disabled={isloading}
              loading={isloading}
              onClick={handleEditBlog}
              label="Update Blog Post"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default BlogPageEdit;
