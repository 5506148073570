import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlog } from '../../Api/userapi';
import HeroSection from '../../components/HeroSection/HeroSection';
import BlogCard from '../../components/BlogCard/BlogCard';
import { saveAllBlogs } from '../../Redux/slice/blogSlice';
import { useParams } from 'react-router-dom';
import Fade from 'react-reveal/Fade';


const CategoryPage = () => {
  const params = useParams();
  const data = useSelector((state) => state?.saveAllBlogs?.data);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const formattedType =
    params?.type?.charAt(0)?.toUpperCase() +
    params?.type?.slice(1)?.toLowerCase();
  const dispatch = useDispatch();

  const handleGetBlogById = async () => {
    try {
      const response = await getAllBlog();
      dispatch(saveAllBlogs(response?.data?.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data && formattedType) {
      const filtered = data.filter((blog) => blog.category === formattedType);
      setFilteredBlogs(filtered);
    }
  }, [data, formattedType]);

  useEffect(() => {
    handleGetBlogById();
  }, []);

  return (
    <>
      {/* <HeroSection /> */}
      <Fade bottom>
        <BlogCard
          data={filteredBlogs}
          title={`Explore more in ${formattedType}`}
        />
      </Fade>
    </>
  );
};

export default CategoryPage;
