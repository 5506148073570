import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.token = action.payload;
    },
    logoutUser: (state, action) => {
      state.token = null;
    },
  },
});

export const { loginUser, logoutUser } = authSlice.actions;
export default authSlice.reducer;
