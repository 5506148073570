import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/cooking-logo.png';
import { FaInstagram } from 'react-icons/fa';
import { FaTiktok } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';

function Footer() {
  const content = [
    {
      title: 'Home',
      href: '/',
    },
    { title: 'Events', href: '/search/events' },
    { title: 'Courses', href: '/search/courses' },
    { title: 'Recipes', href: '/search/recipes' },
    // {
    //   title: "Privacy Policy",
    //   href: "/privacy-policy",
    // },
  ];
  const AboutMe = [
    {
      title: 'Contact Us',
      href: '/contactus',
    },
    {
      title: 'FAQ',
      href: '/frequently-asked-questions',
    },
    {
      title: 'About Website',
      href: '/about',
    },
  ];
  const letsTalk = [
    // {
    //   title: 'Number here',
    //   // href: "",
    // },
    {
      title: 'fadiasfoodadventure@outlook.com',
      // href: 'mailto:alijutt030627@gmail.com',
    },
  ];

  return (
    <>
      <div className="bg-teal-900">
        <footer class=" mx-auto w-10/12 mt-12 pt-12 pb-5 px-4 lg:px-0">
          <div class="flex flex-wrap">
            <div class="w-full lg:w-2/5">
              <div className="h-[100px] w-[100px]">
                <img
                  src={Logo}
                  height="100%"
                  className="rounded-full"
                  width="100%"
                  alt="logo"
                />
              </div>
              <p class="text-white hidden lg:block p-0 lg:pr-12 mt-4">
                My mission is to dig into cuiturai cuisines and heip peopie
                sharethewarmthof getting and eating together athome,
                makefriends, sociaiise and discover culinary treasures
                community..
              </p>
            </div>
            <div class="w-full mt-6 lg:mt-0 md:w-1/2 lg:w-1/5">
              <h6 class="font-semibold text-white mb-4">Content</h6>
              <ul>
                {content.map((item) => (
                  <li>
                    <Link
                      to={item.href}
                      class="block text-white transition ease-in-out duration-300 hover:underline hover:underline-offset-8 hover:font-medium  py-2"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div class="w-full mt-6 lg:mt-0 md:w-1/2 lg:w-1/5">
              <h6 class="font-semibold text-white mb-4">About </h6>
              <ul>
                {AboutMe.map((item) => (
                  <li>
                    <Link
                      to={item.href}
                      class="block text-white transition ease-in-out duration-300 hover:underline hover:underline-offset-8 hover:font-medium  py-2"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div class="w-full mt-6 lg:mt-0 md:w-1/2 lg:w-1/5">
              <h6 class="font-semibold text-white mb-4">Let's Talk</h6>
              <ul>
                <Link class="block text-white transition ease-in-out duration-300 hover:underline hover:underline-offset-8 hover:font-medium  py-2">
                  fadiasfoodadventure@outlook.com
                </Link>
                <div class="flex flex-row ">
                  <Link
                    to="https://wa.me/447796298661"
                    class="block text-white transition ease-in-out duration-300 hover:underline hover:underline-offset-8 hover:font-medium mx-4 py-2"
                  >
                    <FaWhatsapp size={30} />
                  </Link>
                  <Link
                    to="https://www.instagram.com/fadiasfoodadventure"
                    class="block text-white transition ease-in-out duration-300 hover:underline hover:underline-offset-8 hover:font-medium mx-4 py-2"
                  >
                    <FaInstagram size={30} />
                  </Link>
                  <Link
                    to="https://www.tiktok.com/@fadiasfoodadventure"
                    class="block text-white transition ease-in-out duration-300 hover:underline hover:underline-offset-8 hover:font-medium mx-4 py-2"
                  >
                    <FaTiktok size={30} />
                  </Link>
                  <Link
                    to="https://www.facebook.com/fadiasfoodadventure"
                    class="block text-white transition ease-in-out duration-300 hover:underline hover:underline-offset-8 hover:font-medium mx-4 py-2"
                  >
                    <FaFacebook size={30} />
                  </Link>
                </div>
              </ul>
            </div>
          </div>
          <div class="bg-white mt-4">
            <div class="mx-auto flex max-w-screen-xl flex-col gap-y-4 px-4 py-3 text-center text-teal-900 sm:flex-row sm:justify-between sm:text-left">
              <div class="">© 2024 Fadia | All Rights Reserved</div>
              <div class="">
                <Link class="mx-2" to="#">
                  Privacy Policy
                </Link>
                <span>|</span>
                <Link class="mx-2" to="#">
                  Terms of Service
                </Link>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Footer;
