import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { changePassword } from "../../Api/userapi";
import Toast from "../../components/Toast/Toast";
import SpinnerButton from "../../components/Spinner/Spinner";

function UserAccount({ profileData }) {
  const { token } = useSelector((state) => state?.authSlice?.token);
  const [isloading, setIsloading] = useState(false);
  const [passwords, setpasswords] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setpasswords({
      ...passwords,
      [name]: value,
    });
  };

  const handlePasswordChange = async () => {
    if (!passwords.oldPassword && !passwords.newPassword) {
      return Toast.warning("Required all fields");
    }
    setIsloading(true);
    try {
      await changePassword(token, passwords);
      Toast.success("Your password Change!");
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      console.error("ERROR:", error);
      Toast.error(`${error?.response?.data?.message}`);
    }
  };

  return (
    <div class="col-span-8 overflow-hidden rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow">
      <div class="pt-4">
        <h1 class="py-2 text-2xl font-semibold">Account settings</h1>
        <p class="font- text-slate-600">
          Your Account Settings You Can Change Your Password
        </p>
      </div>
      <hr class="mt-4 mb-8" />
      <p class="py-2 text-xl font-semibold">Email Address</p>
      <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <p class="text-gray-600">
          Your email address is <strong>{profileData?.email}</strong>
        </p>
        <Link
          to={"/contactus"}
          class="inline-flex text-sm font-semibold text-teal-600 underline decoration-2"
        >
          Change
        </Link>
      </div>
      <hr class="mt-4 mb-8" />
      <p class="py-2 text-xl font-semibold">Password</p>
      <div class="flex items-center">
        <div class="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-3">
          <label for="login-password">
            <span class="text-sm text-gray-500">Current Password</span>
            <div class="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-teal-600">
              <input
                name="oldPassword"
                value={passwords.oldPassword}
                onChange={handleChange}
                class="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                placeholder="***********"
              />
            </div>
          </label>
          <label for="login-password">
            <span class="text-sm text-gray-500">New Password</span>
            <div class="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-teal-600">
              <input
                name="newPassword"
                value={passwords.newPassword}
                onChange={handleChange}
                class="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                placeholder="***********"
              />
            </div>
          </label>
        </div>
      </div>
      <p class="mt-2">
        Can't remember your current password.{" "}
      </p>
      <SpinnerButton
        disabled={isloading}
        loading={isloading}
        onClick={handlePasswordChange}
        label="Change Password"
      />
      {/* <button
        onClick={handlePasswordChange}
        class="mt-4 rounded-lg bg-teal-600 px-4 py-2 text-white"
      >
        Save Password
      </button> */}
      <hr class="mt-4 mb-8" />
    </div>
  );
}

export default UserAccount;
