import { combineReducers } from "redux";
import authSlice from "./slice/authSlice";
import saveAllBlogs from "./slice/blogSlice";

const rootReducer = combineReducers({
  authSlice: authSlice,
  saveAllBlogs: saveAllBlogs,
});

export default rootReducer;
