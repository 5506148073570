import axios from "axios";

const API_BASE_URL = "http://localhost:9001/api/v1"; // Replace with your API base URL
const API_PRODUCTION_URL = "https://cooking-blogs-mern-stack.vercel.app/api/v1";
const userApi = axios.create({
  // baseURL: API_BASE_URL,
  baseURL: API_PRODUCTION_URL,
});

export const userLoginApi = async (data) => {
  return userApi.post("/auth/login", data);
};
export const userRegisterApi = async (data) => {
  return userApi.post("/auth/register", data);
};

export const emailSend = async (data) => {
  return userApi.post("/contact/send-mail", data);
};

export const postBlogApi = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return userApi.post("/blog", data, config);
};

export const editBlogApi = async (data, token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return userApi.patch(`/blog/${id}`, data, config);
};

export const getBlogByIdApi = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return userApi.get(`/blog/${id}`, config);
};

export const deleteBlogByIdApi = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return userApi.delete(`/blog/${id}`, config);
};

export const getMyBlogs = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return userApi.get(`/blog`, config);
};

export const getAllBlog = async () => {
  return userApi.get(`/common`);
};
export const commonBlogByIdApi = async (id) => {
  return userApi.get(`/common/getBlogById/${id}`);
};

export const getMyProfile = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return userApi.get(`/user`, config);
};

export const changePassword = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return userApi.patch(`/user/password-change`, data, config);
};
