import React from 'react';
import Fade from 'react-reveal/Fade';

const Aboutus = () => {
  return (
    // <div className="container mt-4">
    //   <h1 className="mb-4">About Us</h1>
    //   <p className="lead">
    //     Welcome to our blog! We are passionate writers dedicated to sharing
    //     valuable content with our readers.
    //   </p>
    //   <p className="lead">
    //     Our goal is to provide insightful articles on various topics, including
    //     technology, lifestyle, travel, and more.
    //   </p>
    //   <p className="lead">
    //     Feel free to explore our blog and discover interesting stories, tips,
    //     and advice.
    //   </p>
    //   <h2 className="mt-5 mb-3">Our Mission</h2>
    //   <p>At our blog, we strive to:</p>
    //   <ul>
    //     <li>Inform and educate our audience on diverse subjects.</li>
    //     <li>Inspire creativity and curiosity through engaging content.</li>
    //     <li>Provide practical tips and solutions to everyday challenges.</li>
    //   </ul>
    //   <h2 className="mt-5 mb-3">Meet Our Team</h2>
    //   <p>
    //     Our team consists of talented writers, editors, and designers who work
    //     together to deliver high-quality content.
    //   </p>
    //   <p>
    //     We are dedicated to delivering articles that inform, entertain, and
    //     inspire our readers.
    //   </p>
    //   {/* Add more content as needed */}
    // </div>
    <div class="flex flex-col justify-center items-center ">
      <div class="relative flex flex-col items-center rounded-[20px] w-[700px] max-w-[95%] mx-auto bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white  p-3">
        <Fade bottom>
          <div class="mt-2 mb-8 w-full">
            <h4 class="px-2 text-xl font-bold text-navy-700 dark:text-white">
              About Me
            </h4>
            <p class="mt-2 px-2 text-base text-gray-600">
              My name is Fadia and I'm from Lebanon. I love cooking and the
              smell of freshly prepared food. At the heart of the Middle East on
              the Mediterranean coast, and over the centuries, home to several
              different civilisations, the Ottoman empire and at one time also
              French administration. This has resulted in an unique cooking
              style internationally recognised.
            </p>
          </div>
        </Fade>
        <Fade bottom>
          <div class="mt-2 mb-8 w-full">
            <h4 class="px-2 text-xl font-bold text-navy-700 dark:text-white">
              My Believe
            </h4>
            <p class="mt-2 px-2 text-base text-gray-600">
              I Believe that food has a unique power to unite people, enable
              conversations and con dnect us to our roots. My mission is to dig
              into cultural cuisines and help people share the warmth of getting
              and eating together at home, make friends, socialise and discover
              culinary treasures community I am a foodie offering authentic
              cooking experiences
            </p>
          </div>
        </Fade>
        <Fade bottom>
          <div class="mt-2 mb-8 w-full">
            <h4 class="px-2 text-xl font-bold text-navy-700 dark:text-white">
              Mission
            </h4>
            <p class="mt-2 px-2 text-base text-gray-600">
              I aim to make everyday cooking enjoyable and believe that cooking
              is key to a happy and healthy life. I offer home-cooked food,
              cookery classes, and promote food-sharing apps to help people
              connect and share recipes.
            </p>
          </div>
        </Fade>
        <Fade bottom>
          <div class="mt-2 mb-8 w-full">
            <h4 class="px-2 text-xl font-bold text-navy-700 dark:text-white">
              Community involvement
            </h4>
            <p class="mt-2 px-2 text-base text-gray-600">
              I've worked with the St. Albans Community Pantry, creating meals
              with rescue food and planning a second food bank site. I've also
              supported 'Helping the Homeless' with my cooking. My journey began
              with sharing budget-friendly recipes and has evolved into creating
              a line of dips and homemade Kombucha.
            </p>
          </div>
        </Fade>

        {/* <div class="grid grid-cols-2 gap-4 px-2 w-full">
          <div class="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p class="text-sm text-gray-600">Education</p>
            <p class="text-base font-medium text-navy-700 dark:text-white">
              Stanford University
            </p>
          </div>

          <div class="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p class="text-sm text-gray-600">Languages</p>
            <p class="text-base font-medium text-navy-700 dark:text-white">
              English, Spanish, Italian
            </p>
          </div>

          <div class="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p class="text-sm text-gray-600">Department</p>
            <p class="text-base font-medium text-navy-700 dark:text-white">
              Product Design
            </p>
          </div>

          <div class="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p class="text-sm text-gray-600">Work History</p>
            <p class="text-base font-medium text-navy-700 dark:text-white">
              English, Spanish, Italian
            </p>
          </div>

          <div class="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p class="text-sm text-gray-600">Organization</p>
            <p class="text-base font-medium text-navy-700 dark:text-white">
              Simmmple Web LLC
            </p>
          </div>

          <div class="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p class="text-sm text-gray-600">Birthday</p>
            <p class="text-base font-medium text-navy-700 dark:text-white">
              20 July 1986
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Aboutus;
