import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function BlogCard({ data, title }) {
  const [isvisible, setIsvisible] = useState(8);
  const navigate = useNavigate();
  const handleNavigate = (item) => {
    navigate(`/blog-detail/${item._id}`);
  };
  const handleLoadMore = () => {
    setIsvisible((pre) => pre + 8);
  };
  return (
    <div className="pb-10">
      <div className="justify-center  mx-auto max-w-full px-6 lg:px-0 flex gap-3 w-5/6">
        <div class="flex mt-8 mb-4 px-4 lg:px-0 items-center justify-between">
          <h2 class="font-bold text-3xl text-teal-900">{title}</h2>
        </div>
      </div>
      <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 justify-start  mx-auto w-10/12">
        {data &&
          data?.slice(0, isvisible)?.map((item) => (
            <div onClick={() => handleNavigate(item)} className="border-2">
              <div className="h-[250px] w-[100%] overflow-hidden">
                <img
                  className="h-[100%] w-[100%] object-contain transition-transform duration-300 ease-in-out transform hover:scale-110 cursor-pointer"
                  src={item?.image}
                  alt="technology"
                />
              </div>
              <div className="p-4 pl-0">
                <Link
                  to={`/blog-detail/${item._id}`}
                  className="inline-block py-2 rounded text-green-900 mt-2 ml-auto"
                >
                  Read more
                </Link>
                <h2 className="font-bold text-gray-800 cursor-pointer">
                  {item.title}
                </h2>
              </div>
            </div>
          ))}
      </div>
      {isvisible < data.length && (
        <button
          onClick={handleLoadMore}
          className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 flex items-center justify-center mx-auto rounded mt-4"
        >
          Load More
        </button>
      )}
    </div>
  );
}

export default BlogCard;
