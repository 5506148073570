import React, { lazy, Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from '../components/navbar/navar';
import Test from '../components/test/test';
import Footer from '../components/Footer/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserPagesLayout from '../components/UserPagesLayout/UserPagesLayout';
import LazyLoader from '../components/LazyLoader/LazyLoader';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import Aboutus from '../pages/Aboutus/Aboutus';
import Faq from '../pages/FAQ/Faq';
import Settings from '../pages/Settings/Settings';
import CategoryPage from '../pages/CategoryPage/CategoryPage';
import BlogPageEdit from '../pages/AddBlog/BlogPage';

const HomePage = lazy(() => import('../pages/Home/Home'));
const Login = lazy(() => import('../pages/Login/Login'));
const Register = lazy(() => import('../pages/Register/Register'));
const AddBlog = lazy(() => import('../pages/AddBlog/AddBlog'));
const BlogDetail = lazy(() => import('../pages/BlogDetail/BlogDetail'));
const Contactus = lazy(() => import('../pages/Contactus/Contactus'));
const MyBlogs = lazy(() => import('../pages/MyBlogs/MyBlogs'));

function AppRouter() {
  const location = useLocation();
  return (
    <>
      {!location.pathname.startsWith('/admin/login') &&
        !location.pathname.startsWith('/registration') && <Navbar />}
      <ToastContainer />
      <ScrollToTop />
      <Routes>
        <Route
          path="/registration"
          element={
            <Suspense fallback={<LazyLoader />}>
              <Register />
            </Suspense>
          }
        />
        <Route
          path="/admin/login"
          element={
            <Suspense fallback={<LazyLoader />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <Suspense fallback={<LazyLoader />}>
              <HomePage />
            </Suspense>
          }
        />
        <Route
          path="/search/:type"
          element={
            <Suspense fallback={<LazyLoader />}>
              <CategoryPage />
            </Suspense>
          }
        />
        <Route
          path="/contactus"
          element={
            <Suspense fallback={<LazyLoader />}>
              <Contactus />
            </Suspense>
          }
        />
        <Route
          path="/create-blog"
          element={
            <Suspense fallback={<LazyLoader />}>
              <AddBlog />
            </Suspense>
          }
        />
        <Route
          path="/my-blogs"
          element={
            <Suspense fallback={<LazyLoader />}>
              <UserPagesLayout>
                <MyBlogs />
              </UserPagesLayout>
            </Suspense>
          }
        />
        <Route
          path="/edit-blog/:id"
          element={
            <Suspense fallback={<LazyLoader />}>
              <BlogPageEdit />
            </Suspense>
          }
        />
        <Route
          path="/blog-detail/:id"
          element={
            <Suspense fallback={<LazyLoader />}>
              <BlogDetail />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<LazyLoader />}>
              <UserPagesLayout>
                <Aboutus />
              </UserPagesLayout>
            </Suspense>
          }
        />
        <Route
          path="/frequently-asked-questions"
          element={
            <Suspense fallback={<LazyLoader />}>
              {/* <UserPagesLayout> */}
              <Faq />
              {/* </UserPagesLayout> */}
            </Suspense>
          }
        />
        <Route
          path="/settings"
          element={
            <Suspense fallback={<LazyLoader />}>
              {/* <UserPagesLayout> */}
              <Settings />
              {/* </UserPagesLayout> */}
            </Suspense>
          }
        />
        <Route path="/test" element={<Test />} />
      </Routes>
      {!location.pathname.startsWith('/login') &&
        !location.pathname.startsWith('/registration') && <Footer />}
    </>
  );
}

export default AppRouter;
