import ProfilePage from "../ProfilePage/ProfilePage";
import UserAccount from "../UserAccount/UserAccount";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMyProfile } from "../../Api/userapi";

const Settings = () => {
  const [activatab, setActivatab] = useState("Accounts");

  const tabs = [
    // {
    //   id: 1,
    //   name: "Profile",
    // },
    {
      id: 2,
      name: "Accounts",
    },
  ];

  const handleTabChange = (item) => {
    setActivatab(item?.name);
  };

  const [profileData, setProfileData] = useState(null);
  const { token } = useSelector((state) => state?.authSlice);

  const handleGetProfileInformation = async () => {
    try {
      const response = await getMyProfile(token?.token);
      setProfileData(response?.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    handleGetProfileInformation();
  }, []);

  return (
    <div class="mx-4 min-h-screen max-w-screen-xl sm:mx-8 xl:mx-auto">
      <h1 class="border-b py-6 text-4xl font-semibold">Settings</h1>
      <div class="grid grid-cols-8 pt-3 sm:grid-cols-10">
        <div class="relative my-4 w-56 sm:hidden">
          <input
            class="peer hidden"
            type="checkbox"
            name="select-1"
            id="select-1"
          />
          <label
            for="select-1"
            class="flex w-full cursor-pointer select-none rounded-lg border p-2 px-3 text-sm text-gray-700 ring-teal-700 peer-checked:ring"
          >
            {activatab}
          </label>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="pointer-events-none absolute right-0 top-3 ml-auto mr-5 h-4 text-slate-700 transition peer-checked:rotate-180"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
          <ul class="max-h-0 select-none flex-col overflow-hidden rounded-b-lg shadow-md transition-all duration-300 peer-checked:max-h-56 peer-checked:py-3">
            {tabs.map((item, index) => (
              <>
                <li
                  onClick={() => handleTabChange(item)}
                  class="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-teal-700 hover:text-white"
                >
                  {item?.name}
                </li>
              </>
            ))}

            {/* </li>
            <li class="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-teal-700 hover:text-white">
              Team
            </li>
            <li class="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-teal-700 hover:text-white">
              Others
            </li> */}
          </ul>
        </div>

        <div class="col-span-2 hidden sm:block">
          <ul>
            {tabs.map((item, index) => (
              <li
                onClick={() => handleTabChange(item)}
                class={`mt-5 cursor-pointer ${
                  item?.name === activatab
                    ? " border-l-2 border-l-teal-700 text-teal-700"
                    : "border-transparent"
                } px-2 py-2 font-semibold  transition hover:border-l-teal-700 hover:text-teal-700`}
              >
                {item?.name}
              </li>
            ))}
          </ul>
        </div>
        {/* {activatab === "Profile" && <ProfilePage profileData={profileData} />} */}
        {activatab === "Accounts" && <UserAccount profileData={profileData} />}
      </div>
    </div>
  );
};

export default Settings;
